<template>
    <div class="game">
        <p>我的第一个游戏</p>
        <div id="download">
            <p>网页版试玩:<button><a href="https://www.baolin.link/sunnyland/" target="_blank">欢迎试玩</a></button></p>
            <p>下载地址:<button><a href="https://berlpublic.oss-cn-shenzhen.aliyuncs.com/my2d/formal/my2d.rar" target="_blank">点击下载</a></button></p>
        </div>
        <div class="introd" v-for="(index, num) in versionList" :key="num">
            <p>{{index.title}}</p>
            <p>{{index.content}}</p>
            <div id="tips">
                <p>更新时间:{{index.uptime}}</p>
                <span class="iconfont icon-31dianzan" @click="thumbs">{{index.likes}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'GameIntrod',
  props:[],
  components: {
   
  },
  data() {
    return {
        versionList: null,
    };
  },
  //方法 函数写这里
  methods: {
    getList: function(){
        const that = this;
        this.$axios.get("http://47.119.135.206:8000/api/getUserList").then(function(response){
            console.log(response.data.result);
            response.data.result.forEach(index => {
                that.renderTime(index.uptime);
            });
            that.versionList = response.data.result;
            //console.log(that.versionList);
        })
    },
    renderTime: function(date) {
        console.log(date);
        var dateee = new Date(date).toJSON();
        return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    thumbs: function(event){
        //console.log("event", event);
        event.path[0].childNodes[0].data = parseInt(event.path[0].childNodes[0].data) + 1;
        var version_text = event.path[2].innerText;
        var version_num = version_text.indexOf("v");
        var vers = version_text.slice(version_num + 1, version_num + 5);
        //console.log(vers);
        this.$axios.post("http://47.119.135.206:8000/api/thumbsup", {
            "version" : vers,
            "likes" : event.path[0].childNodes[0].data
        }).then(res=>{
            console.log(res);
        })
    }
  },
  //计算属性
  computed: {},
  //侦听器
  watch: {},
  //过滤器
  filters: {},
  //以下是生命周期
  //组件创建之前
  beforeCreate() {},
  //组件创建之后
  created() {
  },
  //页面渲染之前
  beforeMount() {
    this.getList()
  },
  //页面渲染之后
  mounted() {},
  //页面视图数据更新之前
  beforeUpdate() {},
  //页面视图数据更新之后
  updated() {},
   //页面销毁之前
  beforeDestroy() {},
  //页面销毁之后
  destroyed() {},
}
</script>


<style lang="scss"  scoped>
.game{
    width: 25rem;
    height: 25rem;
    border: 1px solid #000;
    border-radius: 1rem;
    position: fixed;
    right: 1rem;
    bottom: 5rem;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.game a{
    text-decoration: none;
    color: blue;
}

#download{
    width: 20rem;
    margin-bottom: 1rem;
    display: flex;
}

.introd{
    width: 20rem;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid #000;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
}

.introd p{
    margin: 0;
    padding: 0;
    text-align: left;
    white-space: pre-wrap;
}

#tips{
    width: 20rem;
    margin: 0;
    display: flex;
    align-items: flex-end;
}

#tips p{
    font-size: 6px;
}

#tips span{
    cursor: pointer;
}
</style>