<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HelloWorld msg="Welcome to My Vue.js App"/>
    <!-- <GuestBook/> -->
    <GameIntrod/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import GameIntrod from '@/components/GameIntrod.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    GameIntrod
  }
}
</script>
